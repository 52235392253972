import React from 'react';
import '../Assets/CSS/Preloader.css';
import  brandLogo from "../Assets/Images/Icons/brand_logo.png"


const Preloader = () => {
  return (
    <div className="preloader">
      <img src={brandLogo} alt="Logo" height={95} className='loader_img'/>
    </div>
  );
};

export default Preloader;
