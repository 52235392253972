import AssessmentCard from "./JsonAssessment";
import "../Assets/CSS/Assessment.css";
import { RiArrowRightSLine } from "react-icons/ri";
const Assessment = () => {
  return (
    <>
      <div className="assement_wrapper">
         <div>
          <h3 className="scdule">Schedule a free Assessment</h3>
         </div>


        <div className="assess_flex">

          {
          AssessmentCard.map((crt, ind) => (
            <div className="main_er">
             <img src={crt.imgs} 
             className="asss_img" />
             <h2 className="ass_ti">{crt.title}</h2>
             <h3 className="ass_sub_t">{crt.sub_till}</h3>
             <h3 className="rebtn">{crt.rebtn}<RiArrowRightSLine style={{marginTop:"10px"}}/></h3>
            </div>
          ))
          }
        </div>
      </div>
      
    </>
  );
};
export default Assessment;
