import "../Assets/CSS/About.css";
import microsoft from "../Assets/Images/Logoes/microsoft-512.webp";
import databricks from "../Assets/Images/Logoes/databricks.png";
import aws from "../Assets/Images/Logoes/awss.jpg";
import ingram from "../Assets/Images/Logoes/ingram.png";
import saleforce from "../Assets/Images/Logoes/saleforcs.png"
import hcl from "../Assets/Images/Logoes/hcl.png"
import mercure from "../Assets/Images/Logoes/mercury_gate.webp"
import adobe from "../Assets/Images/Logoes/adobess.png"
import wasabi from "../Assets/Images/Logoes/wasabis.webp"
const About = () => {
  return (
    <>
      <div className="about_wrapper">
        <div className="full_wrapper">

          <div className="about_d">
            <div className="about_1">
              <div className="skill_fl">
                <img src={microsoft} className="icon"/>
              </div>
              <div className="skill_fl">
                <img src={databricks} className="icon" />
              </div>
              <div className="skill_fl">
                <img src={aws}className="icon"/>
              </div>
            </div>

            <div className="about_2">
              <div className="skill_fl">
                <img src={ingram} className="icon" />
              </div>
              <div className="skill_fl">
                <img src={saleforce}className="icon" />
              </div>
              <div className="skill_fl">
                <img src={hcl}className="icon"/>
              </div>
            </div>


            <div className="about_2">
              <div className="skill_fl">
                <img src={mercure} className="icon" />
              </div>
              <div className="skill_fl">
                <img src={adobe} className="icon"/>
              </div>
              <div className="skill_fl">
                <img src={wasabi} className="icon"/>
              </div>
            </div>
          </div>
          {/* <div className="about_fl1">
            <div className="skill_fl"></div>
            <div className="skill_fl"></div>
            <div className="skill_fl"></div>
          </div> */}

          <div className="para">
            <h4 className="sub_title">Co-innovating with industry leaders</h4>
            <br></br>
            <h2 className="main_tit">
              Stronger Together: Building Success Through Strategic Partnerships
            </h2>
            <p className="about_para">
              Mk e-tech Private Limited, we believe in the power of
              collaboration. By partnering with industry leaders, we can offer
              our clients a comprehensive suite of best-in-class solutions and
              services. Our strategic partnerships empower us to stay at the
              forefront of technology innovation, ensuring we deliver the most
              effective and future-proof solutions to meet your unique business
              needs. Here’s a glimpse into our valued technology partnerships:
            </p>
            <button className="rd_m">READ MORE</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
