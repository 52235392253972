import "../Assets/CSS/Together.css";
import { FaRegUserCircle } from "react-icons/fa";
const Together=()=>{
    return(
        <>
        <div className="together_wrapper">
            <div className="toger_flex">
                <h2>Let’s Work Together</h2>
                <div className="btns">
                <div>
                <button className="future_btn">Future Clients</button>
                    </div>
                    <div>      
                <button className="future_employees">Future Employees{" "}<FaRegUserCircle/></button>
                    </div>
                </div>
            </div>



        </div>
        </>
    )
}
export default Together