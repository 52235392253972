import "../Assets/CSS/Copyright.css";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
const Copyright = () => {
  return (
    <>
      <div className="copy_wrapper">
        <span className="desclamer_tilte">
          © Copyright 2024 Mk e-tech Private Limited | All Rights Reserved.
          Privacy | Disclaimer
        </span>
        <br></br>
        <div className="icso">
          <FaLinkedin/>
          <RiTwitterXFill/>
          <FaInstagramSquare/>
          <FaFacebookSquare />
          <FaWhatsapp/>
          <FaTelegramPlane/>
          <FaYoutube/>
        </div>
      </div>
    </>
  );
};
export default Copyright;
