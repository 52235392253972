import "../Assets/CSS/Navbar.css";
import { IoClose } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import logo from "../Assets/Images/logos.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { TbWorld } from "react-icons/tb";
import india from "../Assets/Images/Icons/india.png";
import london from "../Assets/Images/Icons/london.avif";
import sydney from "../Assets/Images/Icons/sydney.avif";
import newyork from "../Assets/Images/Icons/newyork.png";
import vietnam from "../Assets/Images/Icons/vietnam.webp";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <nav>
        <div className="items">
          <div className="d">
            <div className="m">
              <TbWorld style={{ padding: "5px 0px", fontSize: "25px" }} />
              <h4 className="pre">OUR GLOBAL PRESENCE: </h4>{" "}
              <span className="flag">
                {" "}
                <img src={india} height={15} /> INDIA{" "}
                <img src={sydney} height={15} /> SYDNEY{" "}
                <img src={london} height={15} /> LONDON{" "}
                <img src={newyork} height={15} /> NEW YORK{" "}
                <img src={vietnam} height={15} /> VIETNAM
              </span>
            </div>
          </div>

          <div className="txts">
            <h4 className="build">Build Your Dream Project Now Hire Us</h4>
            <button className="hire_us">Hire Us</button>
          </div>
        </div>

        <div class="wrapper">
          <div className="logo">
            <img src={logo} height={84} className="logos" />
          </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul class="nav-links">
            <label for="close-btn" class="btn close-btn">
              <IoClose />
            </label>

            <li>
              <Link to="/">
                <a href="#">Home</a>
              </Link>
            </li>
            <li style={{ textAlign: "justify" }}>
              <a href="#" class="desktop-item">
                Industries <RiArrowDropDownLine />
              </a>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
              </label>
              <ul class="drop-menu" style={{ textAlign: "justify" }}>
                <li>
                  <a href="#">Fintek</a>
                </li>
                <li>
                  <a href="#">Real Estate</a>
                </li>
                <li>
                  <a href="#">Enargy</a>
                </li>
                <li>
                  <a href="#">Banking</a>
                </li>
                <li>
                  <a href="#">Pharma</a>
                </li>
                <li>
                  <a href="#">Healthcare</a>
                </li>
                <li>
                  <a href="#">Welfare</a>
                </li>
                <li>
                  <a href="#">Art</a>
                </li>
                <li>
                  <a href="#">Insurance</a>
                </li>
                <li>
                  <a href="#">Government</a>
                </li>
                <li>
                  <a href="#">Entertainment</a>
                </li>
                <li>
                  <a href="#">Gaming</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#">Resources</a>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
              </label>
              <ul class="drop-menu">
                <li>
                  <a href="#">Blogs</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#" class="desktop-item">
                Investors
              </a>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
              </label>
              <ul class="drop-menu" style={{ textAlign: "justify" }}>
                <li>
                  <a href="#">Presentations</a>
                </li>
                <li>
                  <a href="#">Finalcials</a>
                </li>
                <li>
                  <a href="#">Annual Reports</a>
                </li>
                <li>
                  <a href="#">Company Annoucement</a>
                </li>
                <li>
                  <a href="#">Corporate Governance</a>
                </li>
                <li>
                  <a href="#">Shareholding Pattern</a>
                </li>
                <li>
                  <a href="#">Market Tracker</a>
                </li>
                <li>
                  <a href="#">Investor form/information</a>
                </li>
                <li>
                  <a href="#">Investor Contact Details</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#" class="desktop-item">
               Services
              </a>
              <input type="checkbox" id="showMega" />
              <label for="showMega" class="mobile-item">
              </label>
              <div class="mega-box">
                <div class="content">
                  <div class="row">
                    <header style={{fontWeight:"600",color:"rgb(225, 58, 58)",fontSize:"1.1rem"}}>Technology Practises</header>
                    <ul class="mega-links" style={{ textAlign: "justify" }}>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Digital Commerce
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Software Product
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Cloud & infrastructure
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> DevSecOps
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Digital Quality
                          Assurance
                        </a>
                      </li>
                   
                    </ul>
                  </div>

                  <div class="row">
                    <header style={{fontWeight:"600",color:"rgb(225, 58, 58)",fontSize:"1.1rem"}}>Offerings</header>
                    <ul class="mega-links" style={{ textAlign: "justify" }}>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Generative AI
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> CX infrastructure
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Intelligent Automation
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Application
                          Development & Management
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Training
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="row">
                    <header style={{fontWeight:"600",color:"rgb(225, 58, 58)",fontSize:"1.1rem"}}>Private Euality</header>
                    <ul class="mega-links" style={{ textAlign: "justify" }}>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Private Equality
                          Services
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Enterprises
                          Integrations
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Enterprises IT
                          Security
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Data and Analytics
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <MdOutlineKeyboardArrowRight /> Mobile
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a href="#">Careers</a>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
              </label>
              <ul class="drop-menu" style={{ textAlign: "justify" }}>
                <li>
                  <a href="#">Sent CV</a>
                </li>
                <li>
                  <a href="#">Current Open Position</a>
                </li>
                <li>
                  <a href="#">Why Join Us</a>
                </li>
                <li>
                  <a href="#">Employee Testomonial</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#">Company</a>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
              </label>
              <ul class="drop-menu" style={{ textAlign: "justify" }}>
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Corporate Value</a>
                </li>
                <li>
                  <a href="#">News</a>
                </li>
                <li>
                  <a href="#">HealthTech</a>
                </li>
                <li>
                  <a href="#">Retails and Manufacturing</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#">Tools & Tech Stack</a>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
              </label>
            </li>

            <li>
              <Link to="/contact">
                <a href="#">Contact Us</a>
              </Link>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" class="mobile-item">
                Contact Us <RiArrowDropDownLine />
              </label>
            </li>
          </ul>
          <label for="menu-btn" class="btn menu-btn">
            <GiHamburgerMenu style={{ color: "black" }} />
          </label>
        </div>
      </nav>
    </>
  );
};
export default Navbar;

{
  /* 
<li>
<a href="#" class="desktop-item">
  Services
</a>
<input type="checkbox" id="showDrop" />
<label for="showMega" class="mobile-item">
  Services
</label>
<div class="mega-box">
  <div class="contents" >
    <div class="row">
      <header>Technology Practises</header>
      <ul class="mega-links" style={{textAlign:"justify"}}>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Digital Commerce</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Software Product</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Cloud & infrastructure</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}DevSecOps</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Digital Quality Assurance</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Enterprises Integrations</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Enterprises IT Security</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Data and Analytics</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Mobile</a>
        </li>
      </ul>
    </div>
    <div class="row">
      <header>Offerings</header>
      <ul class="mega-links" style={{textAlign:"justify"}}>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Generative AI</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}CX infrastructure</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Intelligent Automation</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Application Development & Management</a>
        </li>
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Training</a>
        </li>
      </ul>
    </div>
    <div class="row">
      <header>Private Euality</header>
      <ul class="mega-links">
        <li>
          <a href="#"><MdOutlineKeyboardArrowRight/>{" "}Private Equality Services</a>
        </li>
       
      </ul>
    </div>
  </div>
</div>
</li> */
}
