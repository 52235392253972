import software_eng from "../Assets/Images/Logoes/software_enginerr.png";
import cloud from "../Assets/Images/Logoes/cloud.png";
import data_analy from "../Assets/Images/Icons/data_analytics.jpg";
import digital_quality from "../Assets/Images/Icons/assurance.webp";
import enterprice from "../Assets/Images/Logoes/interprise_serive.png";
import it_security from "../Assets/Images/Logoes/Enterpices_IT_Security.webp";
import devOps from "../Assets/Images/Icons/devops.webp";
import mobile from "../Assets/Images/Logoes/mobile.png"
const Cards = [
  {
    img: software_eng,
    Names: "Software Engineering",
    btn: "Read More",
  },
  {
    img: cloud,
    Names: "Cloud & Infrastructure",
    btn: "Read More",
  },
  {
    img: data_analy,
    Names: "Data and Analytics",
    btn: "Read More",
  },
  {
    img: digital_quality,
    Names: "Digital Quality Assurance",
    btn: "Read More",
  },
  {
    img: enterprice,
    Names: "Enterprise Integration",
    btn: "Read More",
  },
  {
    img: it_security,
    Names: "Enterprise IT Security",
    btn: "Read More",
  },
  {
    img: devOps,
    Names: "DevSecOps",
    btn: "Read More",
  },
  {
    img: mobile,
    Names: "Mobile",
    btn: "Read More",
  },
];
export default Cards;
