import api from "../Assets/Images/Logoes/API-Integrations.webp";
import testing from "../Assets/Images/Logoes/Testing-Potential.webp";
import e_commerce from "../Assets/Images/Logoes/E-commerce-.webp";

const AssessmentCard=[
    {
     imgs:api,
      title:"Unleash the Power of Seamless Integrations: API Econsystem back",
      sub_till:"in today's data drivan, seamless communication between these system ...",
      rebtn:"Read More"
    },
    {
        imgs:testing,
        title:"Unleash Your Testing Potential: A Path to  World-Class Quality with SAQAMA",
        sub_till:"in Today's competititve landscape. exceptional software quality is the cornerstone ...",
        rebtn:"Read More"
    },
    {
        imgs:e_commerce,
        title:"Rev Up Your Online Sales with the Mk e-tech Commerce Maturity Assessment",
        sub_till:"in today's dynamic e-commerce landscape, even minor gliches in your online ...",
        rebtn:"Read More"
    },
]
export default AssessmentCard;