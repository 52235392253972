import "../Assets/CSS/Contact.css";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import Together from "./Together";
import Footer from "./Footer";
import Copyright from "./copyright";

const Contact = () => {
//   const [userData, setUserData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     Message: "",
//   });

//   let name, value;
//   const handleChange = (e) => {
//     name = e.target.name;
//     value = e.target.value;
//     setUserData({ ...userData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const { firstName, lastName, email, phone, Message } = userData;
//     const db = fetch("https://trading-apps-396a9-default-rtdb.firebaseio.com/StudentsRecord.json", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         firstName,
//         lastName,
//         email,
//         phone,
//         Message,
//       }),
//     })
//     .then((success)=>{
//       toast("Success")
//     })
//     .catch((err)=>{
//       toast("error")
//     })
//   };
  return (
    <div>
      <h2>Contact US</h2>
      <div class="main-block">
        {/* <div class="left-part">
          <div className="li">
            <FaPhone />
            <div className="dd" style={{ textAlign: "justify" }}>
              <div>Give us a call</div>
              <div>
                Line 1: +918010907727<br></br>
                Line 2: +919136007425
              </div>
            </div>
          </div>

          <div className="li">
            <div className="dd" style={{ textAlign: "justify" }}>
              <div>Email us</div>
              <div>info@investmentempire</div>
            </div>
          </div>

          <div className="li">
            <div className="dd" style={{ textAlign: "justify" }}>
              <div>Our offfice</div>
              <div>
                B-13, 2nd Floor, Prozone Trade Center, beside Prozone Mall, MIDC
                Industrial Area, Chilkalthana, Chhatrapati Sambhajinagar
                (Aurangabad), Maharashtra 431003
              </div>
            </div>
          </div>
        </div> */}

        <form>
          <h1>Contact Us</h1>
          <div class="info">
            <input
              class="fname"
              type="text"
              name="firstName"
              placeholder="First Name *"
              required
            />
            <input
              class="fname"
              type="text"
              name="lastName"
              placeholder="Last Name *"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email *"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone number *"
              required
            />
          </div>
          <p>Message</p>
          <div>
            <textarea
              rows="4"
              name="Message"
            ></textarea>
          </div>
          <button type="submit" href="/" className="subb">
            Submit
          </button>
        </form>
      </div>
      <Together/>
      <Footer/>
      <Copyright/>
      <div className="iconss">
        <div className="faw">
           <h3 className="enus">Enquire Now</h3>
        </div>
      </div>
    </div>
  );
};
export default Contact;
