import "../Assets/CSS/Footer.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import brandLogo from "../Assets/Images/Icons/brand_logo.png";
const Footer = () => {
  return (
    <>
      <div className="footer_wrapper">
        <div className="footer_flex">
          <div className="first_1">
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Industries
            </h2>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Services
            </h2>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Resources
            </h2>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Send CV
            </h2>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Investors
            </h2>
          </div>
          <div className="first_1">
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> About Us
            </h2>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Careers
            </h2>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Contact Us Us
            </h2>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Privacy
            </h2>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight /> Disclaimer
            </h2>
          </div>
          <div
            className="first_1"
            style={{ textAlign: "justify", fontWeight: "600" }}
          >
            <h3
              style={{
                color: "white",
                fontWeight: "600",
                fontSize: "1.1rem",
                textAlign: "justify",
              }}
            >
              Customer Support E-Mail Id
            </h3>
            <h2 className="itns" style={{ textAlign: "justify" }}>
              <MdOutlineKeyboardArrowRight />
              cs@mketech.in
            </h2>
            <h3
              style={{ color: "white", fontWeight: "600", fontSize: "1.1rem" }}
            >
              For further details contact at:
            </h3>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight />
              info@mketech.in
            </h2>
            <h3
              style={{ color: "white", fontWeight: "600", fontSize: "1.1rem" }}
            >
              HR Department :
            </h3>
            <h2 className="itns">
              <MdOutlineKeyboardArrowRight />
              hr@mketech.in
            </h2>
            <h3
              style={{ color: "white", fontWeight: "600", fontSize: "1.1rem" }}
            >
              Marketing Department :
            </h3>
            <h2 className="itns" style={{ textAlign: "justify" }}>
              <MdOutlineKeyboardArrowRight />
              marketing@mketech.in
            </h2>
          </div>
          <div className="first_1" style={{ textAlign: "justify" }}>
            <h3 style={{ color: "white", fontWeight: "600" }}>
              Corporate Office
            </h3>
            <h2 className="addres">
              <HiOutlineBuildingOffice /> B-13, 2nd Floor, Prozone Trade Center,
              beside Prozone Mall, MIDC Industrial Area, Chilkalthana,
              Chhatrapati Sambhajinagar (Aurangabad), Maharashtra 431003
            </h2>
            {/* <h2 className="itns">
              <FaPhoneAlt /> +91 91555-99968
            </h2> */}
            <h2 className="itns">
              <FaLocationDot /> All Locations
            </h2>

          </div>
        </div>

        {/* slider separate */}
        <div class="slider">
          <div class="slides">
            <div class="slide">
              {/* silder 1 */}
              <div className="slide_flr">
                <h3>Blockchain Development</h3>
                <h3>Digital Commerce</h3>
                <h3>Software Product Engineering</h3>
                <h3>Cloud & infrastructure</h3>
                <h3>Digital Quality Assurance</h3>
                <h3>DevSecOps</h3>
                <h3>Enterprises IT Security</h3>
                <h3>Data and Analytics</h3>
                <h3>Cloud storage and file-sharing services</h3>
                <h3>Google Cloud Platform</h3>
                <h3>AWS Billing and Cost Management</h3>
              </div>
            </div>
            <div class="slide">
              <div className="slide_flr">
                <h3>Mobile</h3>
                <h3>Generative AI</h3>
                <h3>CX infrastructure</h3>
                <h3>Intelligent Automation</h3>
                <h3>Application Development</h3>
                <h3>Front-End Web & Mobile</h3>
                <h3> Cloud Financial Management</h3>
                <h3>Security, Identity, & Compliance</h3>
                <h3> Networking & Content Delivery</h3>
                <h3>End User Computing</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
